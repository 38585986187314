<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <textarea :class="classes" v-bind="attributes" v-model="model.value"/>
</template>

<script>
export default {
  name: 'FormTextarea',
  props: {
    model: { type: Object, default: undefined },
    name: { type: String, required: true },
    placeholder: { type: String, default: undefined },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    auto: { type: String, default: undefined },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
    rows: { type: Number, default: undefined },
    cols: { type: Number, default: undefined },
    spellcheck: { type: Boolean, default: true },
    wrap: { type: String, default: undefined }
  },
  computed: {
    classes () {
      const classes = ['form-control']

      return classes.join(' ')
    },
    attributes () {
      return {
        type: this.type,
        id: this.name,
        name: this.name,
        placeholder: this.placeholder,
        required: this.required,
        readonly: this.readonly,
        disabled: this.readonly ? true : this.disabled,
        autocomplete: this.auto || false,
        min: this.min,
        max: this.max,
        rows: this.rows,
        cols: this.cols,
        spellcheck: this.spellcheck,
        wrap: this.wrap
      }
    }
  }
}
</script>
