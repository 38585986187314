<template>
  <div>
    <portal to="page-top-title">Edit Label #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'campaigns-labels-single', params: { id: id } }" icon="fa-eye" text>Back to Label</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="campaigns-labels-list" :url="`/campaigns/labels/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
        <row>
          <column-input :md="12" :sm="12">
            <form-group-textarea name="description" :rows="3" :model="formData.description" :max="255" required>
              <p slot="help">* 255 characters max.</p>
            </form-group-textarea>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupTextarea from '@/views/components/form/FormGroupTextarea'
import Form from '@/modules/utils/Form'

export default {
  name: 'CampaignsLabelsEdit',
  metaInfo () {
    return { title: `Campaigns | Label #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    FormGroupInput,
    FormGroupCheck,
    FormGroupTextarea
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      loaded: false,
      formData: {
        name: Form.types.input(),
        description: Form.types.input(),
        active: Form.types.boolean()
      }
    }
  },
  created () {
    this.loadEntity()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/campaigns/labels/${this.id}`)
        .then((res) => {
          const { data: { data } } = res

          this.formData.name.value = data.name
          this.formData.description.value = data.description
          this.formData.active.value = data.active

          this.loaded = true
        })
    }
  }
}
</script>
